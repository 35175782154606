<script>
export default {
  name: 'ErrorLayout',
  layout: 'DefaultLayout',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      pageNotFound: '404 Not Found',
      otherError: 'An error occurred',
    }
  },
  head() {
    const title = this.error.statusCode === 404 ? this.pageNotFound : this.otherError
    return {
      title,
    }
  },
}
</script>

<template>
  <v-container fluid fill-height class="page-gradient">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="8" md="6" class="text-center" align="center" align-content="center" justify="center">
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-card elevation="6">
                <v-card-text>
                  <GlobalAppLogo />
                  <v-container>
                    <v-row>
                      <v-col cols="12" class="bg-primary d-flex flex-column align-center">
                        <v-col cols="5" sm="4" md="3" class="mt-2 bg-white">
                          <GlobalAppLogo :fullcolor="true" />
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="deep-orange--text text--darken-2">
                        <h1 v-if="error.statusCode === 404">
                          {{ pageNotFound }}
                        </h1>
                        <h1 v-else>{{ otherError }}</h1>
                      </v-col>
                      <v-col cols="12" class="">
                        <v-btn x-large block color="primary" to="/" nuxt> Home </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
